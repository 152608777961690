<template>
  <div class="project-sales-mortgage-other-detail">
    <div class="container fluid">
      <mortgage-other-detail
        :id="this.$route.params.id"
        :getOtherMortgageAPI="mortgageAPI.getOtherMethodMortgage"
        :goBackRoute="getListingRoute()"
      ></mortgage-other-detail>
    </div>
  </div>
</template>

<script>
import MortgageOtherDetail from "@/components/GlobalComponents/Shared/Banker/MortgageOtherDetail";
import { projectMortgage as mortgageAPI } from "@/api";

export default {
  components: {
    MortgageOtherDetail
  },
  mixins: [],
  props: {
    isAssigned: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return { mortgageAPI };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    getListingRoute() {
      return { name: "ManageProjectMortgageOther" };
    }
  }
};
</script>

<style lang="scss">
</style>
